import {
  makeBannerProps,
  StandardPageProps,
  makeStandardPageProps,
  makeHtml,
} from "../utils/common";
import {
  assignFeefoReviewAPITag,
  checkTradingTabExpiry,
  OnMobileDevice,
} from "../utils";
import BannerComponent from "./mapped-templates/BannerTemplate";
import { useEffect, useState } from "react";
import HeaderComponentTemplate from "./mapped-templates/HeaderTemplate";
import { BannerWidgetContainer } from "./mapped-templates/BannerWidgetContainerTemplate";
import PrimaryContent from "../utils/PrimaryContent";
import PageProperties from "./PagePropertiesTemplate";
import PageHeader from "../components/SemanticTheme/PageHeader";
import { Container } from "@mui/material";
import { useRouter } from "next/router";
import FooterComponentTemplate from "./mapped-templates/FooterTemplate";
import ContentArea from "../utils/ContentArea";
import {
  useIsAuthenticated,
  useIsAuthenticating,
  useLoginPageUrlSelector,
} from "@/store/selectors";
import { MobileTradingTab } from "@components/SemanticTheme/MobileTradingTab";
import { MappedTemplateExperimentType } from "src/types/Content";
import {
  BasicPageEntityResponseCollection,
  BasicPagePrimaryContentDynamicZone,
  FooterEntityResponseCollection,
  HeaderEntityResponseCollection,
  SeoStructuredDataEntityResponse,
} from "src/graphql/generated-strapi/types";
import { ParsedUrlQuery } from "querystring";
import {
  generateResponsiveImgUrlFromCloudflare,
  getResponsiveBannerImageUrl,
  updateAbsoluteLink,
} from "@components/Utils";
import cloudflareLoader from "src/cloudflareLoader";
import { useIsMobile } from "src/hooks/hooks";

interface BasicPageProps {
  rest: {
    strapiContent: {
      data: {
        basicPages: BasicPageEntityResponseCollection;
      };
    };
    headerContent: {
      data: { headers: HeaderEntityResponseCollection };
    };
    footerContent: {
      data: { footers: FooterEntityResponseCollection };
    };
    seoStructuredData: {
      data: {
        seoStructuredData: SeoStructuredDataEntityResponse;
      };
    };
    bslData?: any;
    experiments?: { [key in string]: string | boolean };
    hideSubscribeSticky?: boolean;
    queryData?: ParsedUrlQuery;
    headers: any;
  };
}

const BasicPage = (props: BasicPageProps & MappedTemplateExperimentType) => {
  const experiments = props?.rest?.experiments;
  const seoStructuredData =
    props?.rest?.seoStructuredData?.data?.seoStructuredData?.data?.attributes;
  const basicPageCmsData =
    props?.rest?.strapiContent?.data?.basicPages?.data?.[0]?.attributes;
  const isHomePage = basicPageCmsData?.PageRoute === "/";
  const bannerProps = makeBannerProps(basicPageCmsData || {});
  const tradingTabProps = basicPageCmsData?.TradingTab?.data?.attributes;
  const bannerComponentRef = basicPageCmsData?.BannerComponent;
  const isBookingWidget =
    bannerComponentRef && !!Object.keys(bannerComponentRef)?.length;
  const headerProps =
    props?.rest?.headerContent?.data?.headers?.data?.[0]?.attributes;
  const footerProps =
    props?.rest?.footerContent?.data?.footers?.data?.[0]?.attributes;
  const pageImgQuality = basicPageCmsData?.ImageQuality;
  const primaryContent = basicPageCmsData?.PrimaryContent;
  const stickyBannerProps =
    basicPageCmsData?.StickyBanner?.data?.attributes || null;
  const pageProperties: StandardPageProps = makeStandardPageProps(
    basicPageCmsData?.seo || {},
  );
  const bslData = props?.rest?.bslData ?? null;
  assignFeefoReviewAPITag(pageProperties, basicPageCmsData);
  pageProperties["ShowSubscribeModal"] = basicPageCmsData?.ShowSubscribeModal;

  if (stickyBannerProps && headerProps)
    headerProps["stickyBannerProps"] = stickyBannerProps;
  if (pageImgQuality && headerProps)
    headerProps["pageImgQuality"] = pageImgQuality;
  if (props?.rest?.headers) pageProperties["nextHeaders"] = props.rest.headers;
  if (!!seoStructuredData)
    pageProperties["seoStructuredData"] = seoStructuredData;

  const router = useRouter();
  const isAuthenticated = useIsAuthenticated();
  const isAuthenticating = useIsAuthenticating();
  const loginUrl = useLoginPageUrlSelector();
  const parsedText = (text?: string) => {
    return text ? (
      <div dangerouslySetInnerHTML={makeHtml(text as string)} />
    ) : undefined;
  };
  const [expPrimaryContent, setExpPrimaryContent] =
    useState<BasicPagePrimaryContentDynamicZone[]>(primaryContent);

  const showPaymentMethods = experiments?.showPaymentMethods;

  useEffect(() => {
    if (!showPaymentMethods) {
      const ExpPrimaryContenWithOutRichTextForPaymentMethods =
        primaryContent.filter((component) => {
          const isUnwantedRichTextArea: BasicPagePrimaryContentDynamicZone[] =
            component["textAreas"]?.data.some(
              (textArea) =>
                textArea.attributes.Name ===
                  "Pay-monthly-Payment options intro" ||
                textArea.attributes.Name === "Core-spacer - rich text",
            );
          return !isUnwantedRichTextArea;
        });
      const ExpPrimaryContentWithoutPaymentMethods =
        ExpPrimaryContenWithOutRichTextForPaymentMethods.filter((component) => {
          const isUnwantedDescriptionCard: BasicPagePrimaryContentDynamicZone[] =
            component["descriptionCard"]?.data.some(
              (descriptionCard) =>
                descriptionCard.attributes.Name ===
                "Pay-monthly-description card container - 3 ways to pay",
            );
          return !isUnwantedDescriptionCard;
        });
      setExpPrimaryContent(ExpPrimaryContentWithoutPaymentMethods);
    }
  }, [showPaymentMethods]);

  useEffect(() => {
    if (!isAuthenticated && !isAuthenticating) {
      const pathname = router.asPath;
      if (basicPageCmsData?.AccountAuthenticated)
        !!loginUrl && router.push(`${loginUrl}?returnUrl=${pathname}`);
      return;
    }
  }, [isAuthenticated, isAuthenticating, loginUrl]);

  let nextHeaders: any;
  if (!!props?.rest?.headers) {
    nextHeaders = JSON.parse(props?.rest?.headers);
  }
  const isMobileDevice = OnMobileDevice(nextHeaders);

  const bannerImgURL = isMobileDevice
    ? bannerProps?.mobileBannerImage || bannerProps?.bannerImage
    : bannerProps?.bannerImage;

  const whichIconInHeroMobile = experiments?.whichIconInHeroMobile;
  const H1andSubtitleChangeToShowWhich =
    !!experiments?.H1andSubtitleChangeToShowWhich;
  const mobileYellowBannerDeskandTab =
    !!experiments?.mobileYellowBannerDeskandTab;

  return (
    <>
      <PageProperties {...pageProperties} />
      {bannerProps && (
        <BannerComponent
          isBookingWidget={isBookingWidget}
          queryData={props?.rest?.queryData}
          bannerImageURL={bannerImgURL}
          whichIconInHeroMobile={Boolean(whichIconInHeroMobile)}
          H1andSubtitleChangeToShowWhich={H1andSubtitleChangeToShowWhich}
          mobileYellowBannerDeskandTab={mobileYellowBannerDeskandTab}
          {...bannerProps}
        >
          {headerProps && (
            <HeaderComponentTemplate
              content={headerProps}
              additionalProps={experiments}
              hideSubscribeSticky={props?.rest?.hideSubscribeSticky}
            />
          )}
          {tradingTabProps?.TradingTabInMobile &&
            checkTradingTabExpiry(bannerProps) && (
              <MobileTradingTab
                tradingTab={{
                  url: updateAbsoluteLink(
                    bannerProps?.tradingTabURL?.data?.attributes?.PageRoute,
                  ),
                  tradingTabInMobile: parsedText(
                    tradingTabProps?.TradingTabInMobile,
                  ),
                }}
                isBookingWidget={isBookingWidget}
                mobileYellowBannerDeskandTab={mobileYellowBannerDeskandTab}
              />
            )}
          {bannerComponentRef && isBookingWidget && (
            <BannerWidgetContainer
              mobileYellowBannerDeskandTab={mobileYellowBannerDeskandTab}
            >
              <ContentArea
                content={bannerComponentRef}
                pageImgQuality={pageImgQuality}
                additionalProps={experiments}
              />
            </BannerWidgetContainer>
          )}
        </BannerComponent>
      )}
      {!!basicPageCmsData?.Title ? (
        <Container>
          <PageHeader
            title={basicPageCmsData?.Title || ""}
            subtitle={basicPageCmsData?.PageDescription || ""}
          />
          {primaryContent && (
            <PrimaryContent
              primaryContent={
                !showPaymentMethods ? expPrimaryContent : primaryContent
              }
              bslData={bslData}
              pageImgQuality={pageImgQuality}
              additionalProps={experiments}
            />
          )}
        </Container>
      ) : (
        primaryContent && (
          <PrimaryContent
            primaryContent={
              !showPaymentMethods ? expPrimaryContent : primaryContent
            }
            bslData={bslData}
            pageImgQuality={pageImgQuality}
            additionalProps={experiments}
            isHomePage={isHomePage}
          />
        )
      )}

      {footerProps && (
        <FooterComponentTemplate
          content={{
            ...footerProps,
            experiments,
          }}
        />
      )}
    </>
  );
};

export default BasicPage;
